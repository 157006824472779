.pin-container-row {
	margin-top: 30px;
	margin-bottom: 50px;
	height: auto;
}

.pin-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.pin-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
	padding: 20px;
}

@media (max-width: 600px) {
	.pin-wrapper {
		padding: 10px;
	}
	
	.TextField {
		font-size: 1rem;
	}
	
	.Button {
		font-size: 1rem;
		padding: 10px;
	}
}

/* quick access page */

.quick-access-container {
	background-color: rgba(0, 0, 0, 0.01);
	min-height: 100vh;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 20px 0;
}

.quick-access-content {
	width: 70%;
	background: white;
	border-radius: 10px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	padding: 5rem;
	position: relative;
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
}

.quick-access-loading-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
	.quick-access-container {
		padding: 0 0 20px 0;
	}

	.quick-access-content {
		width: 90%;
		padding: 2rem;
	}
}

@media only screen and (max-width: 480px) {
	.quick-access-container {
		padding: 0 0 20px 0;
	}

	.quick-access-content {
		width: 100%;
		border-radius: 0;
		box-shadow: none;
	}
}